import { useState, useEffect }  from 'react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
import api from "../helpers/api";

const ReadOnlyChat = ({user_id, activity_id }) =>{
    console.log(`ReadOnlyChat:activity_id:${activity_id}`);;
    const [messages, setMessages] = useState([]);

    useEffect(() => {
      const fetchMessages = async () => {
        try { 
          const response = await api.get('/activities/user-chat/' + activity_id + '/' + user_id + '/');
          const messagesFromApi = response.data;
          setMessages(messagesFromApi);
        } catch (error) {
          console.error('Error fetching messages:', error);
        }
      };
  
      fetchMessages();
    }, [user_id, activity_id]);

 
    return( <div className="App">
    <div style={{ position:"relative", height: "600px", width: "700px"  }}>
      <MainContainer>
        <ChatContainer>       
          <MessageList 
            scrollBehavior="smooth" 
            
          >
            {messages.map((message, i) => {
              console.log(message)
              return <Message key={i} model={message} />
            })}
          </MessageList>
               
        </ChatContainer>
      </MainContainer>
    </div>
  </div>)
}

export default ReadOnlyChat;