import { withRouter } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import api from "../../../helpers/api";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import Image1 from "../../../assets/images/card_headers/initial_assesment.png";
import Image2 from "../../../assets/images/card_headers/positive_psychology_step1_boost_your_mood.png";
import Image3 from "../../../assets/images/card_headers/woman_standing_in_field with rainbow_wide.png";

require.context('../../../assets/images/card_headers/');

const Activities = (props) => {
  const [activities, setActivities] = useState([]);

  const coachId = props.location && props.location.state ? props.location.state.coachId : null;

  // Rest of your component...
  useEffect(() => {
    console.log("Actvities useEffect");
    const apiUrl = "/activities/?" + (coachId ? "coachId=" + coachId : "")
    api
    .get(apiUrl)
    .then((res) => res.data)
    .then(
      (result) => {
        console.info(apiUrl);
        console.info(result);

        setActivities(result);
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        console.log(error);
      }
    );
}, []);

  return (

    <div className="card-container d-flex justify-content-around">
    {activities.map((activity, index) => (
      <Card style={{ width: '20rem' }} bg={'light'} text={'dark'}>
        <Card.Img variant="top" src={process.env.REACT_APP_PUBLIC_URL + activity.header_image} />
        <Card.Body>
          <Card.Title>{activity.title}</Card.Title>
          <Card.Text>
          {activity.description}
          </Card.Text>
          {activity.locked ?(
              <span style={{color: 'red'}}>Activity is locked, complete the previous activity</span>
 
           ) : (
            <Button variant="primary" onClick={() => props.history.push("/activity",{ activityId: activity.activity_id })}>Launch</Button>
           )}
        
          
        </Card.Body>
      </Card>
     
    ))}
    </div>

  );
};

export default Activities;