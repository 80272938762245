import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Table,
  OverlayTrigger,
  Tooltip,
  Dropdown,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Card from "../../../../components/Card";
import axios from "axios";
import api from "../../../../helpers/api";
//img
import user1 from "../../../../assets/images/user/1.jpg";
import user2 from "../../../../assets/images/user/2.jpg";
import user3 from "../../../../assets/images/user/3.jpg";
import user4 from "../../../../assets/images/user/4.jpg";
import user5 from "../../../../assets/images/user/5.jpg";
import user6 from "../../../../assets/images/user/6.jpg";
import user7 from "../../../../assets/images/user/1.jpg";

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: null,
    };
    this.deleteUser.bind(this);
  }

  getUser = () => {

    api.get("/users/")
      .then((res) => res.data)
      .then(
        (result) => {
          console.log(result);
          this.setState({
            isLoaded: true,
            items: Object.values(result),
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error);
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  };

  componentDidMount() {
    this.getUser();
  }

  deleteUser = (id) => {
    const post_body = { username: id };

    api.post("/users/trash/", post_body)
      .then((res) => res.data)
      .then(
        (result) => {
          console.log(result);
          this.setState({
            isLoaded: true,
            items: Object.values(result),
          });
          toast.success("user delete successfully");
          this.getUser();
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          alert(error);
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  };

  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* Same as */}
        <ToastContainer />
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card className="card-block card-stretch card-height">
                <Card.Header>
                  <Card.Header.Title>
                    <h4 className="card-title mb-0">User List</h4>
                  </Card.Header.Title>
                  <Link
                    to="/user-profile-edit"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#addContact"
                  >
                    Add New
                  </Link>
                </Card.Header>
                <Card.Body>
                  <Table
                    className="data-tables"
                    responsive
                    style={{ width: "100%" }}
                  >
                    <thead className="light">
                      <tr>
                        <th>Profile</th>
                        <th>Role</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Phone No.</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.items?.map((item) => (
                        <tr key={item.username}>
                          <td>
                            <img
                              src={user1}
                              className="rounded avatar-40 img-fluid"
                              alt="user1"
                            />
                          </td>
                          <td>{item.role}</td>
                          <td>{item.first_name}</td>
                          <td>{item.last_name}</td>
                          <td>{item.username}</td>
                          <td>{item.phone}</td>
                          <td>
                            <div className="d-flex align-items-center list-action">
                              <OverlayTrigger
                                placement={"top"}
                                overlay={<Tooltip>Rating</Tooltip>}
                              >
                                <Link
                                  to="/"
                                  onClick={(e) => e.preventDefault()}
                                  className="badge bg-warning-light mr-2"
                                >
                                  <i className="far fa-star"></i>
                                </Link>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement={"top"}
                                overlay={<Tooltip>View</Tooltip>}
                              >
                                <Link
                                  className="badge bg-success-light mr-2"
                                  to={{
                                    pathname: "/user-profile",
                                    state: {
                                      username: item.username,
                                    },
                                  }}
                                >
                                  <i className="lar la-eye"></i>
                                </Link>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement={"top"}
                                overlay={<Tooltip>Action</Tooltip>}
                              >
                                <span
                                  className="badge bg-primary-light"
                                  data-original-title="Action"
                                >
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      as="span"
                                      className="text-primary action-item"
                                    />
                                    <Dropdown.Menu>
                                    <Dropdown.Item
                                        as={Link}
                                        to={{
                                          pathname: "/chat",
                                          state: { username: item.username },
                                        }}
                                      >
                                        View Chats
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        as={Link}
                                        to={{
                                          pathname: "/auth/sign-in",
                                          state: { view_as: item.username },
                                        }}
                                      >
                                        View As
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        as={Link}
                                        to={{
                                          pathname: "/user-profile-edit",
                                          state: { username: item.username },
                                        }}
                                      >
                                        Edit
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        as={Link}
                                        to="/"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.deleteUser(item.username);
                                        }}
                                      >
                                        Trash
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </span>
                              </OverlayTrigger>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
export default UserList;
