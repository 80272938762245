import React from 'react'
import {Container,Row,Col} from 'react-bootstrap'

const BlankPage = () => {
    return (
        <>
          <Container fluid>
                <Row>
                    <Col lg="12">
                    Here Add Your React Content.....
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default BlankPage;