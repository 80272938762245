import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import jwtDecode from 'jwt-decode';

const API_URL = process.env.REACT_APP_API_URI;

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const access_token = JSON.parse(localStorage.getItem("user"));
  config.headers["Authorization"] = `Bearer ${access_token}`;

  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {

   if(error.response?.status !== 401) {
        return Promise.reject(error);
   }

   if(error.config?._isRetry === true){
        return Promise.reject(error);
   }
  if (error.response) {
    console.log(JSON.stringify(error));
    // Access Token was expired
    if (
      error.response.status === 401 
    ) {
      const refresh_token = JSON.parse(localStorage.getItem("refresh-user"));

      let tokenExpiration = jwtDecode(refresh_token).exp;

      let dateNow = new Date();

      if(tokenExpiration < dateNow.getTime()/1000){
         window.location.href = '/auth/sign-in';
         return Promise.reject(error);
      }
      else
      {
          let post_body = {};

          const view_as_user = localStorage.getItem('view-as-user');
          if(view_as_user){
                post_body = {'view_as':view_as_user};
          }

          const bearer = `Bearer ${refresh_token}`;
          console.log(bearer);
          try {
            const rs = await axios.post(`${API_URL}auth/refresh`, post_body, {
                  headers: {
                    'Authorization': bearer 
                  }});

            const  token  = rs.data["access_token"];

            localStorage.setItem("user", JSON.stringify(token));

            error.config.headers['Authorization'] = 'Bearer ' + token;
            error.config._isRetry = true;
            //error.config.baseURL = undefined;
            return axios.request(error.config);
          } catch (_error) {
              console.log(_error);
              return Promise.reject(_error);
          }
      }


    }
  }
  return Promise.reject(error);
};

export const setupInterceptorsTo = (
  axiosInstance: AxiosInstance
): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};