import React from 'react';

function CoachingSessionStep() {
    return (
        <div>
            Coaching Session Step
        </div>
    );
}

export default CoachingSessionStep;

