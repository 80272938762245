import React, { useState, useEffect } from "react";
import DOMPurify from 'dompurify'
import {
  Container,
  Row,
  Col,
  Collapse,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";
import api from "../../../helpers/api";

const Emaildetail = (props) => {
  // Collapse state
  const [emailInfo, setEmailInfo] = useState();

  const getEmailInfo = () => {
    api.get(
        "/email/get/" +
        props.location.state.id +
        "/"
    )
      .then((res) => res.data)
      .then(
        (result) => {
          result["html_body"] = DOMPurify.sanitize(result["html_body"]);
          setEmailInfo(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          alert(error);
        }
      );
  };

  useEffect(() => {
    if (props.location?.state?.id !== undefined) {
      getEmailInfo();
    }
    return () => {};
  }, []);

  console.log(emailInfo);

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
              <h4 className="font-weight-bold">Email Details</h4>
              <Link
                to="/email"
                className="btn btn-primary btn-sm d-flex align-items-center justify-content-between"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2">Back</span>
              </Link>
            </div>
          </Col>
          <Col lg="12">
            <Card>
              <Card.Body>
                <Row className="pb-4 mx-0 card-header-border">
                  <Col lg="12" className="mb-3">
                    <svg
                      width={30}
                      height={30}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                      />
                    </svg>
                  </Col>
                  <Col lg="12">
                    <div className="text-left">
                      <h5 className="font-weight-bold mb-2">Sender</h5>
                      <p className="mb-0">{emailInfo?.from_email || ""}</p>
                      <h5 className="font-weight-bold mt-2 mb-2">Receiver</h5>
                      <p className="mb-0">{emailInfo?.to_email || ""}</p>
                      <h5 className="font-weight-bold mt-2 mb-2">Timestamp</h5>
                      <p className="mb-0">{emailInfo?.timestamp || ""}</p>
                    </div>
                  </Col>
                </Row>
                <Row className="pt-4 pb-5 mx-0">
                  <Col lg="12">
                    <div className="text-left">
                      <h5 className="font-weight-bold mb-2">Subject</h5>
                      <p className="mb-0 mb-1">{emailInfo?.subject || ""}</p>
                      <h5 className="font-weight-bold mb-2">Message</h5>
                      <p
                        className="mb-0 mb-1"
                        dangerouslySetInnerHTML={{
                          __html: emailInfo?.html_body,
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Emaildetail;
