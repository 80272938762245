import { useEffect, useState } from 'react';
import api from "../helpers/api";

function AnalyzeChat({ activityId, onAnalyzeClick }) {

  const [goals, setGoals] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    console.log(activityId);
    
    api
    .get("/activities/knowledge/" + activityId + "/")
    .then((res) => res.data)
    .then(
      (result) => {
        console.info(result);
        console.info("knowledge made it");
        if(result && result.hasOwnProperty("goals")){
          setGoals(result["goals"]);
          onAnalyzeClick();
        }
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        console.log(error);
      }
    );
}, [activityId, onAnalyzeClick]);

  const fetchGoals = async () => {
    if (isAnalyzing) {
      console.log("Analyze in progress, button disabled");
      return;
    }
    setIsAnalyzing(true);
    const response = await call_analyze_chat_api();
    const data = await response;

    setGoals(data.goals);

    onAnalyzeClick();
    setIsAnalyzing(false);
  };

  async function call_analyze_chat_api(){
  return api
        .post("/activities/analyzechat/" + activityId + "/")
        .then((res) => res.data)
        .then(
          (result) => {
            console.info(result);
            return JSON.parse(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log(error);
            if (error.response && error.response.data && error.response.data.message) {
              setErrorMessage(error.response.data.message);
            } else {
              setErrorMessage('An error occurred.');
            }
            return {"goals": []};
          });
        };

  return (
    <>
        <h2>Use the Analyze Button, for our AI to learn about you from your chats:</h2>
        <button onClick={fetchGoals}>Analyze Chat</button>
        {isAnalyzing && <p style={{color: 'red'}}>Analyzing, please wait...</p>}
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        {goals && goals.length > 0 ? (
          <>
            <h2>Your Goals:</h2>
            <ul>
              {goals.map((goal, index) => (
                <li key={index}>{goal?.title}
                  {goal?.subgoals && goal.subgoals.map((subgoal, subIndex) => (
                    <ul key={subIndex}>
                      <li>{subgoal  }</li>
                    </ul>
                  ))}
                </li>
              ))}
            </ul>
          </>
        ) : (
          <p></p>
        )}
    </>
  );
}

export default AnalyzeChat;