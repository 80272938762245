import React, { useEffect, useState} from 'react';
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from "react-redux";
import jwtDecode from 'jwt-decode';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector(state => state.auth)
  const [isAuthenticated, setIsAuthenticated] = useState(null)  
  useEffect(() => {

    let token = localStorage.getItem('refresh-user')
        if(token){

            let tokenExpiration = jwtDecode(token).exp;
            //alert(tokenExpiration);
            let dateNow = new Date();

            if(tokenExpiration < dateNow.getTime()/1000){
                setIsAuthenticated(false)
                //alert("token expired")
            }else{
                setIsAuthenticated(true)
                //alert("token valid")
            }
        } else {
           setIsAuthenticated(false)
        }
    // eslint-disable-next-line
  }, [auth])

  if(isAuthenticated === null){

    return <></>
  }

 //alert(isAuthenticated);

  return (

    <Route {...rest} render={props =>
      !isAuthenticated ? (
        <Redirect to='/auth/sign-in'/>
      ) : (
        <Component {...props} />
      )
    }
    />
  );
};

export default PrivateRoute;