import { Row, Col } from "react-bootstrap";
import Card from "../components/Card";
import React from "react";
import MessageComponent from "./Messagecomponent";
import api from "../helpers/api";

class ChatContent extends React.Component {
  componentDidMount() {
    api
      .get("/messages/by-customer/" + this.props.id)
      .then((res) => res.data)
      .then(
        (result) => {
          console.info(result);
          console.info("made it");
          this.setState({
            isLoaded: true,
            items: result,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      counter: 0,
      message: "",
    };
    this.myFunction = this.myFunction.bind(this);

    this.handleChange = this.handleChange.bind(this);
  }



  handleChange(event) {
    this.setState({ message: event.target.value });
  }
  myFunction(e) {
    const post_body = {
      message: this.state.message,
      to_sms: this.props.contact,
      from_sms: "+18446571505",
    };
    api
      .post("/sms/send/", post_body)
      .then((response) => response.data)
      .then((data) => this.setState({ postId: data.msg }))
      .catch((error) => {
        this.setState({ errorMessage: error.toString() });
       
      });
    e.preventDefault();
  }

  render() {
    return (
      <div
        className={`chat-content animate__animated animate__fadeIn ${
          this.props.active ? "active" : ""
        }`}
        data-toggle-extra="tab-content"
        id={`user-content-${this.props.id}`}
      >
        <Card>
          <div className="right-sidenav" id="first-sidenav">
            <div className="d-flex">
              <button
                type="button"
                className="btn btn-sm"
                data-extra-dismiss="right-sidenav"
              >
                <i className="las la-times"></i>
              </button>
            </div>
            <div className="chat-profile mx-auto">
              <div
                className="avatar avatar-70 avatar-borderd avatar-rounded mx-auto"
                data-toggel-extra="right-sidenav"
                data-target="#first-sidenav"
              >
                <img
                  src={`../assets/images/${this.props.id}`}
                  alt="users"
                  className="img-fluid"
                />
              </div>
              <h4 className="mb-2">{this.props.name}</h4>
              <h6 className="mb-2">{this.props.about}</h6>
            </div>
            <div className="chat-detail">
              <Row>
                <Col md="6" className="col-6 title">
                  Nick Name:
                </Col>
                <Col md="6" className="col-6 text-right">
                  {this.props.nickName}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md="6" className="col-6 title">
                  Tel:
                </Col>
                <Col md="6" className="col-6 text-right">
                  {this.props.contact}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md="6" className="col-6  title">
                  Date Of Birth:
                </Col>
                <Col md="6" className="col-6 text-right">
                  {this.props.dob}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md="6" className="col-6 title">
                  Gender:
                </Col>
                <Col md="6" className="col-6 text-right">
                  {this.props.gender}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md="6" className="col-6 title">
                  Language:
                </Col>
                <Col md="6" className="col-6 text-right">
                  {this.props.lang}
                </Col>
              </Row>
            </div>
          </div>
          <Card.Header className="chat-content-header">
            <div className="d-flex align-items-center">
              <button
                className="btn text-primary bg-primary-light btn-sm d-block d-lg-none mr-2"
                data-toggel-extra="side-nav"
                data-expand-extra=".chat-left-wrapper"
              >
                <i className="las la-arrow-right"></i>
              </button>
              <div
                className="avatar-50 avatar-borderd avatar-rounded"
                data-toggel-extra="right-sidenav"
                data-target="#first-sidenav"
              >
                <img src={this.props.img} alt="users" className="img-fluid" />
              </div>
              <div className="chat-title">
                <h5>{this.props.name}</h5>
                <small>{this.props.status}</small>
              </div>
            </div>
            <div className="chat-action">
              <div
                className="nav"
                id={`user-${this.props.id}-action`}
                role="tablist"
              >
                <button
                  className="btn text-primary bg-primary-light btn-sm"
                  id="user-{{id}}-video-call-tab"
                >
                  <svg
                    width="20"
                    height="20"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                    />
                  </svg>
                </button>
                <button
                  className="btn text-primary bg-primary-light btn-sm ml-2"
                  id="user-{{id}}-call-tab"
                >
                  <svg
                    width="20"
                    height="20"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </Card.Header>
          <Card.Body
            className="msg-content"
            id={`user-${this.props.id}-chat-content`}
          >
            <div className="msg-list">
              {this.state.items &&
                this.state.items.map((row, key) => {
          
                  let isAgentMessage = row?.message_from_type === "agent";
                  let userString = isAgentMessage ? "user" : "";
                  let topString = isAgentMessage ? "topright" : "topleft";
           
                  return (
                    <div className={`single-msg ${userString}`} key={key}>
                      <div
                        className={`triangle-${topString} single-msg-shap`}
                      ></div>
                      <div className={`single-msg-content ${userString}`}>
                        <div className="msg-detail">
                          {row.message_type === "sms" ? (
                            <svg
                              width="20"
                              height="20"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                              width="20"
                              height="20"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                              />
                            </svg>
                          )}
                          <span>
                            <MessageComponent message={row.message} />
                          </span>
                        </div>
                        <div className="msg-action pl-2">
                          <span>{row.timestamp}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Card.Body>
          <Card.Footer className="px-3 py-3">
            <form onSubmit={this.myFunction}>
              <div className="input-group input-group-sm">
                <input
                  type="text"
                  value={this.state.message}
                  onChange={this.handleChange}
                  className="form-control"
                  id={`chat-input${this.props.id}`}
                  placeholder="Enter here..."
                  aria-label="Recipient's username"
                  aria-describedby={`basic-addon2-${this.props.id}`}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="input-group-text chat-icon"
                    id={`basic-addon${this.props.id}`}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="las la-smile"></i>
                  </button>
                  <div
                    className="dropdown-menu p-0 border-0"
                    aria-labelledby={`basic-addon${this.props.id}`}
                  >
                    <emoji-picker data-target-input="#chat-input"></emoji-picker>
                  </div>
                </div>
                <div className="input-group-append">
                  <span
                    className="input-group-text chat-icon"
                    id={`basic-addon2-${this.props.id}`}
                  >
                    <input type="submit" disabled={this.props.isSmsEnabled === false ? true : false}></input>
                    <i className="lab la-telegram-plane"></i>
                  </span>
                </div>
              </div>
            </form>
          </Card.Footer>
        </Card>
      </div>
    );
  }
}

export default ChatContent;
