import jwt_decode from "jwt-decode";

export function IsUserAdmin() {

		let token = localStorage.getItem('user')
        if(token){

			var decoded = jwt_decode(token);
	        console.log(decoded);
	        var is_admin = decoded["is_administrator"];
	        console.log("is_administrator?" + is_admin);
	        if(is_admin === true)
	        {
	           console.log("user is administrator");
	           return is_admin;
	        }
	        else
	        {
	        	return false;
	        }

	    }

	    return false;

}
