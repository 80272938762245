import React,{ useState, useEffect } from 'react'
import { Container, Row, Col, Nav, Tab } from 'react-bootstrap'
import Card from '../../../components/Card'

//Chatcomponents
import ReadOnlyChat from '../../../components/ReadOnlyChat'
import api from "../../../helpers/api";



const Chat = (props) => {

    const [userChats, setUserChats] = useState([]);
    const [chatData, setChatData] = useState(null);

    const loadChat = (user_name, activity_id) => {
        console.log(`load chat: ${user_name} ${activity_id}`);
        setChatData({ user_name, activity_id });
      
    }

    useEffect(() => {
        console.log("Chat useEffect:");
        const username = props?.location?.state?.username;
        var api_url = "/messages/latest-chats/";
        if(username !== undefined){ 
            api_url += "?username=" + username;
        }

        api
        .get(api_url)
        .then((res) => res.data)
        .then(
          (result) => {
            console.info(result);
            setUserChats(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log(error);
          }
        );
    }, []);

    return (
    <>
        <Container fluid>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col lg="4" className="chat-left-wrapper">
                        <div className="chat-list">
                            <Card>
                                <div className="card-header">
                                    <div className="d-flex justify-content-between align-items-center mb-2">
                                        <h4 className="card-title">Messages</h4>
                                        <button className="btn text-primary bg-primary-light btn-sm d-block d-lg-none" data-toggel-extra="side-nav-close" data-expand-extra=".chat-left-wrapper">
                                            <i className="las la-arrow-left"></i>
                                        </button>
                                    </div>
                                    <div className="form-group mb-0">
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">
                                                    <svg className="svg-icon text-primary" id="search" width="16" height="16" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <input type="text" className="form-control form-control-sm" placeholder="Enter name" aria-label="Username" aria-describedby="basic-addon1" />
                                            <div className="input-group-append d-none">
                                                <span className="input-group-text">
                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 <nav>
                                    <Nav as="ul" variant="tabs justify-content-around" className="" defaultActiveKey="/nav-home" role="tablist">
                                        <Nav.Item as="li">
                                            <Nav.Link  eventKey="nav-home" role="tab" active>Recent</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link  eventKey="nav-profile" role="tab">Archive</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link  eventKey="nav-contact" role="tab">New</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </nav>
                                <Card.Body className="item-list">
                                    <ul id="chat-list" style={{padding:0, minHeight:"calc(100vh - 21rem)", maxHeight: "calc(100vh - 21rem)", overflowY: "scroll", overflowX: "hidden"}}>
                                        {userChats.map((chat, index) => (
                                            <li className="simple-item hover" onClick={() => loadChat(chat.user_name, chat.activity_id)} data-toggle-extra="tab">
            
                                          <div className="simple-item-body">
                                            <div className="simple-item-title">
                                                <h5 className="title-text">{chat.user_name}</h5>
                                                <div className="simple-item-time"> <span>{chat.timestamp}</span></div>
                                            </div>
                                            <div className="simple-item-content">
                                            <span className="simple-item-text short">
                                                {chat.title}
                                            </span>
                                            </div>
                                          </div>
                                          </li>
                                        ))}

                                    </ul>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                    <Col lg="8" className="chat-right-wrapper">

                         {chatData && <ReadOnlyChat user_id={chatData.user_name} activity_id={chatData.activity_id} />}
    

                    </Col>
                </Row>
            </Tab.Container>
        </Container>
     </>
    )
}

export default Chat