import ActivityChat from '../../../components/ActivityChat';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useLocation } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import api from "../../../helpers/api";
import AnalyzeChat from '../../../components/AnalyzeChat';
import NextSteps from '../../../components/NextSteps';
import Questionnaire from '../../../components/Questionnaire';

function InstructionsContent({step}) {
    return (
      <Card style={{ width: '30rem' }} bg={'light'} text={'dark'}>
          <Card.Img variant="top" src={process.env.REACT_APP_PUBLIC_URL + step.image} />
          <Card.Body>
              <Card.Title>{step?.title}</Card.Title>
              <Card.Text>
              {step?.instructions}
              </Card.Text>
          </Card.Body>
        </Card>
    );

}

  const Activity = (props) => {

    //const [activity, setActivity] = useState(null);
    //const [image, setImage] = useState(null);
    const [steps, setSteps] = useState([]);
    const [isNextStepsEnabled, setNextStepsEnabled] = useState(false);

    const activityId = props.location && props.location.state ? props.location.state.activityId : null;

    const handleAnalyzeClick = useCallback(() => {
      setNextStepsEnabled(true);
    }, []); // No dependencies, so the callback never changes
  
    
    useEffect(() => {
        console.log("ActivityModule useEffect: activityId:" + activityId);
        
        api
        .get("/activities/activity/" + activityId + "/steps")
        .then((res) => res.data)
        .then(
          (result) => {
            console.info("/activities/activity/" + activityId + "/steps");
            console.info(result);

            setSteps(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log(error);
          }
        );
    }, []);
    return (
     <>
     
 <Accordion defaultActiveKey="0" flush>
      {steps.map((step, index) => (
        <Accordion.Item eventKey={index.toString()} key={index.toString()}>
          <Accordion.Header>{step.step_title}</Accordion.Header>
          <Accordion.Body>
          {(() => {
              switch (step.step_type) {
                case 'instructions': return <InstructionsContent step={step}></InstructionsContent>;
                case 'chat': return <ActivityChat activityId={step.activity_id}></ActivityChat>;
                case 'analyze_chat': return <AnalyzeChat activityId={step.activity_id} onAnalyzeClick={handleAnalyzeClick}></AnalyzeChat>;
                case 'next_steps': return <NextSteps props={props} enableButton={isNextStepsEnabled} nextActivityId={JSON.parse(step?.properties)?.nextActivityId}></NextSteps>;
                case 'questionnaire': return <Questionnaire activityId={step.activity_id}></Questionnaire>;
                // other cases...
                default: return <div>Default content</div>;
              }
            })()}
          </Accordion.Body>
        </Accordion.Item>
      
      ))}

    </Accordion>
    </>)
}

export default Activity;