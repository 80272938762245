import React from 'react';

const NextSteps = ({ props, enableButton, nextActivityId }) => {
    return (
        <div>
            <h2>{enableButton ? "You may proceed to the next activity" : "Complete the steps in this activity to enable the next activity."}</h2>
            <button disabled={!enableButton} onClick={() => props.history.push("/activity",{ activityId: nextActivityId })}>Next Activity</button>
        </div>
    );
};

export default NextSteps;
