import React, { Component } from "react";

class MessageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  handleClick = () => {
    this.setState({ expanded: true });
  };

  render() {
    const { message } = this.props;
    const { expanded } = this.state;
    const maxLength = 150;
    const shortenedMessage = message.substring(0, maxLength);

    return (
      <>
        {expanded ? (
          <span>{message}</span>
        ) : (
          <>
            {shortenedMessage}
            {message.length > maxLength && (
              <span onClick={this.handleClick}>...</span>
            )}
          </>
        )}
      </>
    );
  }
}

export default MessageComponent;
