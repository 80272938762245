// from https://www.bezkoder.com/react-login-example-jwt-hooks/
import axios from "axios";
import jwt_decode from "jwt-decode";

const API_URL = process.env.REACT_APP_API_URI;

const register = (username, email, password) => {
  return axios.post(API_URL + "register", {
    username,
    email,
    password,
  });
};
const login = async (username, password, view_as) => {
  try
  {
    const result = await axios
      .post(API_URL + "login", {
        username,
        password,
        view_as
      })
      .then((response) => {
        if (response.data.access_token) {
          var decoded = jwt_decode(response.data.access_token);
          console.log(decoded);
          var is_admin = decoded["is_administrator"];
          console.log("is_administrator?" + is_admin);
          if(is_admin === true)
          {
            console.log("user is administrator");
          }
          if(decoded["viewing_as"] !== undefined) {
            console.log("viewing as user" + decoded["viewing_as"]);
            localStorage.setItem("view-as-user", decoded["viewing_as"]);
          } 
          else
          {
            localStorage.removeItem("view-as-user");
          }

          localStorage.setItem("user", JSON.stringify(response.data.access_token));
          localStorage.setItem("refresh-user", JSON.stringify(response.data.refresh_token));
          return true;
        }
        return false;
      });
      
      return result;
    }catch(error)
    {
      console.log(error);
      return false
    }
};
const logout = () => {
  localStorage.removeItem("user");
  return axios.post(API_URL + "logout").then((response) => {
    return response.data;
  });
};
const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};
const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
}
export default AuthService;