import React from 'react';

import CoachingSessionStep from '../../../components/CoachingSessionStep';

const BasicWizard = () => {
  
    return (
        <div>
            <Step1></Step1>
            <CoachingSessionStep></CoachingSessionStep>
        </div>
    );
  };

  const Step1 = () => {
  
    return (
      <>
        <button>Previous ⏮️</button>
        <button>Next ⏭</button>
      </>
    );
  };
export default BasicWizard;