import React, { useState } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import Card from "../../../components/Card";
import { connect } from "react-redux";
import { getDarkMode } from "../../../store/mode";
import { ToastContainer, toast } from "react-toastify";
//img
import logo from "../../../assets/images/logo.png";
import darklogo from "../../../assets/images/logo-dark.png";

import { useHistory } from "react-router-dom";
import { Axios } from "axios";
import api from "../../../helpers/api";

function mapStateToProps(state) {
  return {
    darkMode: getDarkMode(state),
  };
}
class RecoverPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      darkMode: false,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  async handleSubmit(event) {
    console.log(this.state.email);
    event.preventDefault();

    const post_body = {
      email: this.state.email,
    };

    api.post("/users/send-reset-password-email/",
      post_body
    )
    .then((response) => response.data)
    .then((result) => {
            // Do something with the data
            console.log(result);
            toast.success("Password link sent successfully on your email");
          })
    .catch((error) => {
            console.error("An error occurred while parsing JSON:", error);
            toast.error("An error occurred while parsing JSON:".error);
          });
  }

  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <section className="login-content">
          <Container className="h-100">
            <Row className="align-items-center justify-content-center h-100">
              <Col md="5">
                <Card className="p-5">
                  <Card.Body>
                    <div className="auth-logo">
                      <img
                        src={logo}
                        className={`img-fluid  rounded-normal  ${
                          !this.props.darkMode ? "d-none" : ""
                        }`}
                        alt="logo"
                      />
                      <img
                        src={darklogo}
                        className={`img-fluid  rounded-normal  ${
                          this.props.darkMode ? "d-none" : ""
                        }`}
                        alt="logo"
                      />
                    </div>
                    <h3 className="mb-3 text-center">Reset Password</h3>
                    <p className="text-center small text-secondary mb-3">
                      You can reset your password here
                    </p>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col lg="12">
                          <Form.Group>
                            <Form.Label className="text-secondary">
                              Email
                            </Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter Email"
                              value={this.state.email}
                              onChange={this.handleEmailChange}
                              required
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Button type="submit" variant="btn btn-primary btn-block">
                        Reset Password
                      </Button>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default RecoverPassword;
