import { useEffect, useState } from 'react';
import api from "../helpers/api";

function Questionnaire({activityId, activityTitle, activityDescription}) {

  const [questions, setQuestions] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state
  const [isIncomplete, setIsIncomplete] = useState(false); // Add isIncomplete state
  const [score, setScore] = useState(null);

  useEffect(() => {
    console.log(activityId);
    
    api
    .get("/activities/questionnaire/" + activityId + "/")
    .then((res) => res.data)
    .then(
      (result) => {
        console.info(result);
        console.info("questionnaire made it");
        if(result){
          setQuestions(result);
        }
        setIsLoading(false); // Set isLoading to false after API call finishes
      },
      (error) => {
        console.log(error);
        setIsLoading(false); // Set isLoading to false if there's an error
      }
    );
}, [activityId]);


  const handleSubmit = () => {
    if (questions.length !== Object.keys(selectedOptions).length) {
      setIsIncomplete(true);
      console.log("Please answer all questions");
    } else {
      setIsIncomplete(false);
      const questionnaireData = questions && questions.map((question) => ({
        question_ordinal: question?.ordinal,
        selected_option: selectedOptions[question?.ordinal],
      }));
      console.log(questionnaireData);
      console.log("posting data");
      api.post("/activities/submit_questionnaire/" + activityId + "/", questionnaireData)
        .then((res) => res.data)
        .then(
          (result) => {
            console.info(result);
            setScore(result.score);
          })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      {isLoading ? ( // Display loading message if isLoading is true
        <p>Loading questionnaire...</p>
      ) : score ? (
        <div>Score: {score}%</div>
      ) : (
        questions && questions.length > 0 ? (
          <>
            <ol>
              {questions.map((question, index) => (
                <li key={index}>
                  {question?.question}
                  {question?.options && (
                    <div>
                      {question.options.map((option, optionIndex) => (
                        <label key={optionIndex} style={{ marginRight: '10px' }}>
                          <input
                            type="radio"
                            name={`${question.ordinal}`}
                            value={`${option?.option_ordinal}`}
                            checked={selectedOptions[`${question.ordinal}`] === option.option_ordinal.toString()}
                            onChange={(e) => {
                              setSelectedOptions({
                                ...selectedOptions,
                                [`${question.ordinal}`]: e.target.value,
                              });
                            }}
                          />
                          {option?.option_text}
                        </label>
                      ))}
                    </div>
                  )}
                </li>
              ))}
            </ol>
            {isIncomplete && <p style={{ color: 'red' }}>Please answer all questions</p>}
            <button onClick={handleSubmit}>Submit</button>
          </>
        ) : (
          <p></p>
        )
      )}
    </div>
  );
}

export default Questionnaire;