import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Card from "../components/Card";
import api from "../helpers/api";

class EmailGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
    };
  }

  componentDidMount() {

    api.get("/email/all/")
      .then((res) => res.data)
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          alert(error);
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }
  render() {
    return (
      <Container fluid>
        <Row>
          <Col lg="12">
            <Card className="card-block card-stretch card-height">
              <Card.Header>
                <Card.Header.Title>
                  <h4 className="card-title mb-0">Email List</h4>
                </Card.Header.Title>
              </Card.Header>
              <Card.Body>
                <Table
                  className="data-tables"
                  responsive
                  style={{ width: "100%" }}
                >
                  <thead className="light">
                    <tr>
                      <th>timestamp</th>
                      <th>Sender</th>
                      <th>receiver</th>
                      <th>Subject</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.items?.map((item) => (
                      <tr key={item.message_id}>
                        <td>{item.timestamp}</td>
                        <td>{item.from_email}</td>
                        <td>{item.to_email}</td>
                        <td>{item.subject}</td>
                        <td>
                          <div className="d-flex align-items-center list-action">
                            <OverlayTrigger
                              placement={"top"}
                              overlay={<Tooltip>View</Tooltip>}
                            >
                              <Link
                                className="badge bg-success-light mr-2"
                                to={{
                                  pathname: "/email-details",
                                  state: {
                                    id: item.message_id,
                                  },
                                }}
                              >
                                <i className="lar la-eye"></i>
                              </Link>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EmailGrid;
