import React from "react";
import { Container, Row, Col, Nav, Tab, Form, Button } from "react-bootstrap";
import Card from "../../../../components/Card";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
//img
import user1 from "../../../../assets/images/user/1.jpg";
import api from "../../../../helpers/api";

class UserProfileEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      error: null,
      isLoaded: false,
      username: null,
    };
    this.editUser.bind(this);
    this.addUser.bind(this);
  }

  componentDidMount() {
    if (this.props.location.state !== undefined) {
      api.get("/users/get/" +
          this.props.location.state.username +
          "/"
      )
        .then((res) => res.data)
        .then(
          (result) => {
            console.log(result);
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            alert(error);
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    } else {
      console.log("Add user");
      this.setState({
        user: {
          approved: 0,
          bio: "",
          city: "",
          country: "",
          first_name: "",
          last_name: "",
          occupation: "",
          password_changed: 0,
          phone: "",
          role: "administrator",
          state: "",
          url: "",
          username: "",
        },
        isOpen: true,
        dialog: {},
      });
    }
  }

  setValueOnChange = (event) => {
    const { name, value } = event.target;
    const user = { ...this.state.user };
    user[name] = value;
    console.log(user);
    this.setState({ user });
  };

  addUser = async () => {

    const post_body= this.state.user;

    await api.post("/users/add/", post_body)
      .then((response) => response.data)
      .then((result) => {
            // Do something with the data
            console.log(result);
            this.setState({
              isOpen: true,
              customer: result,
              dialog: { message: "User Added successfully" },
            });
            toast.success("Add user successfully");
          })
      .catch((error) => {
            console.error("An error occurred while parsing JSON:", error);
          });

  };

  editUser = async () => {

    const post_body= this.state.user;

    await api.post("/users/update/", post_body)
      .then((response) => response.data)
      .then((result) => {
            // Do something with the data
            console.log(result);
            this.setState({
              isOpen: true,
              customer: result,
              dialog: { message: "User Added successfully" },
            });
            toast.success("user profile update successfully");
          })
     .catch((error) => {
            console.error("An error occurred while parsing JSON:", error);
          });

  };

  render() {
    const user = this.state?.user;
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* Same as */}
        <ToastContainer />
        <Container fluid>
          <Tab.Container defaultActiveKey="personal-information">
            <Row>
              <Col lg="12">
                <Card>
                  <Card.Body className="p-0">
                    <div className="mm-edit-list usr-edit">
                      <Nav variant="pills" className="mm-edit-profile d-flex">
                        <li className="col-md-6 p-0">
                          <Nav.Link eventKey="personal-information">
                            Personal Information
                          </Nav.Link>
                        </li>
                        {/* <li className="col-md-3 p-0">
                          <Nav.Link eventKey="chang-pwd">
                            Change Password
                          </Nav.Link>
                        </li> */}
                        <li className="col-md-6 p-0">
                          <Nav.Link eventKey="emailandsms">
                            Email and SMS
                          </Nav.Link>
                        </li>
                        {/* <li className="col-md-3 p-0">
                          <Nav.Link eventKey="manage-contact">
                            Manage User
                          </Nav.Link>
                        </li> */}
                      </Nav>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="12">
                <div className="mm-edit-list-data">
                  <Tab.Content>
                    <Tab.Pane eventKey="personal-information" role="tabpanel">
                      <Card>
                        <Card.Header>
                          <Card.Header.Title>
                            <h4 className="card-title">Personal Information</h4>
                          </Card.Header.Title>
                        </Card.Header>
                        <Card.Body>
                          <Form>
                            {/* <Row className="form-group  align-items-center">
                              <Col md="12">
                                <div className="profile-img-edit">
                                  <div className="crm-profile-img-edit">
                                    <img
                                      className="crm-profile-pic rounded-circle avatar-100"
                                      src={user1}
                                      alt="profile-pic"
                                    />
                                    <div className="crm-p-image bg-primary">
                                      <i className="las la-pen upload-button"></i>
                                      <input
                                        className="file-upload"
                                        type="file"
                                        accept="image/*"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row> */}
                            <Row className=" align-items-center">
                              <Form.Group as={Col} sm="6">
                                <Form.Label>User Name:</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="username"
                                  id="username"
                                  value={user?.username}
                                  readOnly={
                                    this.props.location.state !== undefined
                                      ? true
                                      : false
                                  }
                                  onChange={this.setValueOnChange.bind(this)}
                                />
                              </Form.Group>
                              <Form.Group as={Col} sm="6">
                                <Form.Label>First Name:</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="first_name"
                                  id="first_name"
                                  value={user?.first_name}
                                  placeholder="Enter First Name"
                                  onChange={this.setValueOnChange.bind(this)}
                                />
                              </Form.Group>
                              <Form.Group as={Col} sm="6">
                                <Form.Label>Last Name:</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="last_name"
                                  id="last_name"
                                  value={user?.last_name}
                                  placeholder="Enter Last Name"
                                  onChange={this.setValueOnChange.bind(this)}
                                />
                              </Form.Group>

                              <Form.Group as={Col} sm="6">
                                <Form.Label>Phone:</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="phone"
                                  id="phone"
                                  value={user?.phone}
                                  placeholder="Enter Phone no"
                                  onChange={this.setValueOnChange.bind(this)}
                                />
                              </Form.Group>

                              <Form.Group as={Col} sm="6">
                                <Form.Label>URL:</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="url"
                                  id="url"
                                  value={user?.url}
                                  placeholder="Enter URL"
                                  onChange={this.setValueOnChange.bind(this)}
                                />
                              </Form.Group>

                              <Form.Group controlId="cname" as={Col} sm="6">
                                <Form.Label>City:</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue="Atlanta"
                                  name="city"
                                  value={user?.city}
                                  placeholder="Enter city"
                                  onChange={this.setValueOnChange.bind(this)}
                                />
                              </Form.Group>
                              <Form.Group as={Col} sm="6">
                                <Form.Label>Country:</Form.Label>
                                <Form.Control
                                  as="select"
                                  value={user?.country}
                                  name="country"
                                  onChange={this.setValueOnChange.bind(this)}
                                  id="inputCountry"
                                >
                                  <option>Canada</option>
                                  <option>UK</option>
                                  <option>USA</option>
                                  <option>India</option>
                                  <option>Africa</option>
                                </Form.Control>
                              </Form.Group>
                              <Form.Group as={Col} sm="6">
                                <Form.Label>State:</Form.Label>
                                <Form.Control
                                  as="select"
                                  value={user?.state}
                                  name="state"
                                  onChange={this.setValueOnChange.bind(this)}
                                  id="inputState"
                                >
                                  <option value="AL">Alabama</option>
                                  <option value="AK">Alaska</option>
                                  <option value="AZ">Arizona</option>
                                  <option value="AR">Arkansas</option>
                                  <option value="CA">California</option>
                                  <option value="CO">Colorado</option>
                                  <option value="CT">Connecticut</option>
                                  <option value="DE">Delaware</option>
                                  <option value="DC">District Of Columbia</option>
                                  <option value="FL">Florida</option>
                                  <option value="GA">Georgia</option>
                                  <option value="HI">Hawaii</option>
                                  <option value="ID">Idaho</option>
                                  <option value="IL">Illinois</option>
                                  <option value="IN">Indiana</option>
                                  <option value="IA">Iowa</option>
                                  <option value="KS">Kansas</option>
                                  <option value="KY">Kentucky</option>
                                  <option value="LA">Louisiana</option>
                                  <option value="ME">Maine</option>
                                  <option value="MD">Maryland</option>
                                  <option value="MA">Massachusetts</option>
                                  <option value="MI">Michigan</option>
                                  <option value="MN">Minnesota</option>
                                  <option value="MS">Mississippi</option>
                                  <option value="MO">Missouri</option>
                                  <option value="MT">Montana</option>
                                  <option value="NE">Nebraska</option>
                                  <option value="NV">Nevada</option>
                                  <option value="NH">New Hampshire</option>
                                  <option value="NJ">New Jersey</option>
                                  <option value="NM">New Mexico</option>
                                  <option value="NY">New York</option>
                                  <option value="NC">North Carolina</option>
                                  <option value="ND">North Dakota</option>
                                  <option value="OH">Ohio</option>
                                  <option value="OK">Oklahoma</option>
                                  <option value="OR">Oregon</option>
                                  <option value="PA">Pennsylvania</option>
                                  <option value="RI">Rhode Island</option>
                                  <option value="SC">South Carolina</option>
                                  <option value="SD">South Dakota</option>
                                  <option value="TN">Tennessee</option>
                                  <option value="TX">Texas</option>
                                  <option value="UT">Utah</option>
                                  <option value="VT">Vermont</option>
                                  <option value="VA">Virginia</option>
                                  <option value="WA">Washington</option>
                                  <option value="WV">West Virginia</option>
                                  <option value="WI">Wisconsin</option>
                                  <option value="WY">Wyoming</option>
                                  <option value="NA">None of these</option>
                                </Form.Control>
                              </Form.Group>
                              <Form.Group as={Col} sm="6">
                                <Form.Label bsPrefix="d-block">
                                  Profile Status:
                                </Form.Label>
                                <div className="custom-control custom-radio custom-control-inline">
                                  <Form.Check.Input
                                    type="radio"
                                    id="customRadio6"
                                    name="approved"
                                    bsPrefix="custom-control-input"
                                    onChange={this.setValueOnChange.bind(this)}
                                    checked={user?.approved == "1"}
                                    value="1"
                                  />
                                  <Form.Check.Label
                                    bsPrefix="custom-control-label"
                                    htmlFor="customRadio6"
                                  >
                                    {" "}
                                    Approved{" "}
                                  </Form.Check.Label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                  <Form.Check.Input
                                    type="radio"
                                    id="customRadio7"
                                    name="approved"
                                    bsPrefix="custom-control-input"
                                    onChange={this.setValueOnChange.bind(this)}
                                    checked={user?.approved == "0"}
                                    value="0"
                                  />
                                  <Form.Check.Label
                                    bsPrefix="custom-control-label"
                                    htmlFor="customRadio7"
                                  >
                                    Not Approved
                                  </Form.Check.Label>
                                </div>
                              </Form.Group>
                              <Form.Group as={Col} sm="6">
                                <Form.Label>Occupation :</Form.Label>
                                <Form.Control
                                  as="select"
                                  value={user?.occupation}
                                  name="occupation"
                                  onChange={this.setValueOnChange.bind(this)}
                                  id="inputOccupation"
                                >
                                  <option value="realtor">Realtor</option>
                                  <option value="general contractor">
                                    General Contractor
                                  </option>
                                  <option value="landscaping">
                                    Landscaping
                                  </option>
                                  <option value="roofing">Roofing</option>
                                  <option value="septic">Septic</option>
                                  <option value="electrician">
                                    Electrician
                                  </option>
                                  <option value="plumber">Plumber</option>
                                  <option value="load officer">
                                    Load Officer
                                  </option>
                                </Form.Control>
                              </Form.Group>

                              <Form.Group as={Col} sm="12">
                                <Form.Label>Bio:</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  name="bio"
                                  value={user?.bio}
                                  placeholder="Please enter your bio"
                                  rows="5"
                                  onChange={this.setValueOnChange.bind(this)}
                                  style={{ lineHeight: "22px" }}
                                ></Form.Control>
                              </Form.Group>
                            </Row>
                            <Button type="reset" variant="outline-primary mr-2">
                              Cancel
                            </Button>
                            <Button
                              variant="primary"
                              onClick={
                                this.props.location.state !== undefined
                                  ? this.editUser.bind(this)
                                  : this.addUser.bind(this)
                              }
                            >
                              Submit
                            </Button>
                          </Form>
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="chang-pwd" role="tabpanel">
                      <Card>
                        <Card.Header>
                          <Card.Header.Title>
                            <h4 className="card-title">Change Password</h4>
                          </Card.Header.Title>
                        </Card.Header>
                        <Card.Body>
                          <Form>
                            <Form.Group controlId="cpass">
                              <Form.Label>Current Password:</Form.Label>
                              <Link
                                to="/"
                                onClick={(e) => e.preventDefault()}
                                className="float-right"
                              >
                                Forgot Password
                              </Link>
                              <Form.Control type="Password" />
                            </Form.Group>
                            <Form.Group controlId="npass">
                              <Form.Label>New Password:</Form.Label>
                              <Form.Control type="Password" />
                            </Form.Group>
                            <Form.Group controlId="vpass">
                              <Form.Label>Verify Password:</Form.Label>
                              <Form.Control type="Password" />
                            </Form.Group>
                            <Button type="reset" variant="outline-primary mr-2">
                              Cancel
                            </Button>
                            <Button type="submit" variant="primary">
                              Submit
                            </Button>
                          </Form>
                        </Card.Body>
                      </Card>
                    </Tab.Pane> */}
                    <Tab.Pane eventKey="emailandsms" role="tabpanel">
                      <Card>
                        <Card.Header>
                          <Card.Header.Title>
                            <h4 className="card-title">Email and SMS</h4>
                          </Card.Header.Title>
                        </Card.Header>
                        <Card.Body>
                          <Form>
                            <Form.Group
                              as={Row}
                              className="align-items-center"
                              controlId="emailnotification"
                            >
                              <Form.Label className="col-md-3">
                                Email Notification:
                              </Form.Label>
                              <div className="col-md-9 custom-control custom-switch">
                                <Form.Check.Input
                                  bsPrefix="custom-control-input"
                                  defaultChecked
                                />
                                <Form.Label bsPrefix="custom-control-label"></Form.Label>
                              </div>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              className="align-items-center"
                              controlId="smsnotification"
                            >
                              <Form.Label bsPrefix="col-md-3">
                                SMS Notification:
                              </Form.Label>
                              <div className="col-md-9 custom-control custom-switch">
                                <Form.Check.Input
                                  bsPrefix="custom-control-input"
                                  defaultChecked
                                />
                                <Form.Label bsPrefix="custom-control-label"></Form.Label>
                              </div>
                            </Form.Group>
                            <Form.Group as={Row} className="align-items-center">
                              <Form.Label bsPrefix="col-md-3" htmlFor="npass">
                                When To Email
                              </Form.Label>
                              <div className="col-md-9">
                                <div className="custom-control custom-checkbox">
                                  <Form.Check.Input
                                    bsPrefix="custom-control-input"
                                    id="email01"
                                  />
                                  <Form.Label
                                    bsPrefix="custom-control-label"
                                    htmlFor="email01"
                                  >
                                    You have new notifications.
                                  </Form.Label>
                                </div>
                                <div className="custom-control custom-checkbox">
                                  <Form.Check.Input
                                    bsPrefix="custom-control-input"
                                    id="email02"
                                  />
                                  <Form.Label
                                    bsPrefix="custom-control-label"
                                    htmlFor="email02"
                                  >
                                    You're sent a direct message
                                  </Form.Label>
                                </div>
                                <div className="custom-control custom-checkbox">
                                  <Form.Check.Input
                                    bsPrefix="custom-control-input"
                                    id="email03"
                                    defaultChecked
                                  />
                                  <Form.Label
                                    bsPrefix="custom-control-label"
                                    htmlFor="email03"
                                  >
                                    Someone adds you as a connection
                                  </Form.Label>
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group as={Row} className="align-items-center">
                              <Form.Label bsPrefix="col-md-3" htmlFor="npass">
                                When To Escalate Emails
                              </Form.Label>
                              <div className="col-md-9">
                                <div className="custom-control custom-checkbox">
                                  <Form.Check.Input
                                    bsPrefix="custom-control-input"
                                    id="email04"
                                  />
                                  <Form.Label
                                    bsPrefix="custom-control-label"
                                    htmlFor="email04"
                                  >
                                    {" "}
                                    Upon new order.
                                  </Form.Label>
                                </div>
                                <div className="custom-control custom-checkbox">
                                  <Form.Check.Input
                                    bsPrefix="custom-control-input"
                                    id="email05"
                                  />
                                  <Form.Label
                                    bsPrefix="custom-control-label"
                                    htmlFor="email05"
                                  >
                                    {" "}
                                    New membership approval
                                  </Form.Label>
                                </div>
                                <div className="custom-control custom-checkbox">
                                  <Form.Check.Input
                                    bsPrefix="custom-control-input"
                                    id="email06"
                                    defaultChecked
                                  />
                                  <Form.Label
                                    bsPrefix="custom-control-label"
                                    htmlFor="email06"
                                  >
                                    {" "}
                                    Member registration
                                  </Form.Label>
                                </div>
                              </div>
                            </Form.Group>
                            <Button type="reset" variant="outline-primary mr-2">
                              Cancel
                            </Button>
                            <Button type="submit" variant="primary">
                              Submit
                            </Button>
                          </Form>
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="manage-contact" role="tabpanel">
                      <Card>
                        <Card.Header>
                          <Card.Header.Title>
                            <h4 className="card-title">Manage Contact</h4>
                          </Card.Header.Title>
                        </Card.Header>
                        <Card.Body>
                          <Form>
                            <Form.Group controlId="cno">
                              <Form.Label>Contact Number:</Form.Label>
                              <Form.Control
                                type="text"
                                defaultValue="001 2536 123 458"
                              />
                            </Form.Group>
                            <Form.Group controlId="email">
                              <Form.Label>Email:</Form.Label>
                              <Form.Control
                                type="text"
                                defaultValue="Barryjone@demo.com"
                              />
                            </Form.Group>
                            <Form.Group controlId="url">
                              <Form.Label>Url:</Form.Label>
                              <Form.Control
                                type="text"
                                defaultValue="https://getbootstrap.com"
                              />
                            </Form.Group>
                            <Button type="reset" variant="outline-primary mr-2">
                              Cancel
                            </Button>
                            <Button type="submit" variant="primary">
                              Submit
                            </Button>
                          </Form>
                        </Card.Body>
                      </Card>
                    </Tab.Pane> */}
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </>
    );
  }
}

export default UserProfileEdit;
