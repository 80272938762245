import {Switch,Route} from 'react-router-dom';
import Layout1 from '../layouts/backend/Layout1';
import BlankLayout from '../layouts/BlankLayout';
import PrivateRoute from './PrivateRoute';

const LayoutsRoute = props => {
    return (
        <Switch>
            <Route path="/auth" component={BlankLayout} />
            <Route path="/extra-pages" component={BlankLayout} />
            <PrivateRoute path="/" component={Layout1} />
        </Switch>
    )
}

export default LayoutsRoute