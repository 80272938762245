import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Collapse,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import Card from "../../../components/Card";
import { ToastContainer, toast } from "react-toastify";
import api from "../../../helpers/api";

const Sendemail = ({location, isEmailEnabled}) => {
  
  // Collapse state
  const [fieldValue, setFieldValue] = useState({});

  const sendEmailInfo = () => {
    const json_body = {
      html_body: fieldValue?.message,
      subject: fieldValue?.subject,
      to_email: location.state.email,
    };
    api
      .post("/email/send/", json_body)
      .then((response) => response.data)
      .then((data) => toast.success(data.msg))
      .catch((error) => {
        //this.setState({ errorMessage: error.toString() });
        toast.success(error);
      });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(fieldValue);
    sendEmailInfo();
  };

  const setValueOnChange = (e) => {
    setFieldValue({ ...fieldValue, [e.target.name]: e.target.value });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
      <Container fluid>
        <Row>
          <Col sm="12" lg="12">
            <Card>
              <Card.Header>
                <Card.Header.Title>
                  <h4 className="card-title"> Send Email</h4>
                </Card.Header.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Form.Row>
                    <Col md="12" className="mb-3">
                      <Form.Label htmlFor="validationDefault01">
                        Email
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="sender"
                        // id="validationDefault01"
                        value={
                          location.state !== undefined
                            ? location.state.email
                            : ""
                        }
                        readOnly={
                          location.state !== undefined ? true : false
                        }
                        required
                      />
                    </Col>
                    {/* <Col md="12" className="mb-3">
                      <Form.Label htmlFor="validationDefault01">
                        To Email
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="receiver"
                        //id="validationDefault01"
                        onChange={(e) => setValueOnChange(e)}
                        required
                      />
                    </Col> */}
                    <Col md="12" className="mb-3">
                      <Form.Label htmlFor="validationDefault02">
                        Subject
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="subject"
                        //  id="validationDefault02"
                        required
                        onChange={(e) => setValueOnChange(e)}
                      />
                    </Col>
                    <Col md="12" className="mb-3">
                      <Form.Label htmlFor="validationTextarea">
                        Textarea
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="message"
                        //className="is-invalid"
                        // id="validationTextarea"
                        placeholder="Required example textarea"
                        onChange={(e) => setValueOnChange(e)}
                        required
                      ></Form.Control>
                      {/* <Form.Control.Feedback type="invalid">
                        Please enter a message in the textarea.
                      </Form.Control.Feedback> */}
                    </Col>
                  </Form.Row>
                  <Form.Group className="mb-0">

                    <Button
                      disabled={!location.state.isEmailEnabled}
                      className="primary"
                      onClick={handleSubmit}
                    >
                      Send
                    </Button>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Sendemail;
