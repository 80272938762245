import React, { useState } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import Card from "../../../components/Card";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { getDarkMode } from "../../../store/mode";

//img
import logo from "../../../assets/images/logo.png";
import darklogo from "../../../assets/images/logo-dark.png";

import { useHistory, useLocation } from "react-router-dom";
import api from "../../../helpers/api";

function mapStateToProps(state) {
  return {
    darkMode: getDarkMode(state),
  };
}

const NewPassword = (props) => {
  const [newPassword, setnewPassword] = useState();
  const [confirmPassword, setconfiremPassword] = useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  console.log(searchParams.get("user"), searchParams.get("code"));

  const onSubmit = () => {
    const validatePassword = (password) => {
      var regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z]).{8,}$/;
      return regex.test(password);
    };
    if (validatePassword(newPassword)) {
      if (newPassword === confirmPassword) {
        const post_body={
            email: searchParams.get("user"),
            code: searchParams.get("code"),
            "new-password": newPassword,
            "new-password2": confirmPassword,
          };

        api.post("/users/reset-password/",
          post_body
        )
          .then((response) => response.data)
          .then((result) => {
                // Do something with the data
                toast.success("Password Reset successfully");
              })
          .catch((error) => {
                console.error("An error occurred while parsing JSON:", error);
                toast.error("An error occurred");
            });

      } else {
        toast.error("Password does't match");
      }
    } else {
      toast.error(
        "Password Should be at least 8 characters long has at least one number, symbol, upper case and one lower case"
      );
    }
  };

  const onChangeNewPassword = (e) => {
    setnewPassword(e.target.value);
  };

  const onChangeConfirmPassword = (e) => {
    setconfiremPassword(e.target.value);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
      <section className="login-content">
        <Container className="h-100">
          <Row className="align-items-center justify-content-center h-100">
            <Col md="5">
              <Card className="p-5">
                <Card.Body>
                  <div className="auth-logo">
                    <img
                      src={logo}
                      className={`img-fluid  rounded-normal  ${
                        !props.darkMode ? "d-none" : ""
                      }`}
                      alt="logo"
                    />
                    <img
                      src={darklogo}
                      className={`img-fluid  rounded-normal  ${
                        props.darkMode ? "d-none" : ""
                      }`}
                      alt="logo"
                    />
                  </div>
                  <h3 className="mb-3 text-center">New Password</h3>
                  <p className="text-center small text-secondary mb-3">
                    You can generate your new password here
                  </p>
                  <Form>
                    <Row>
                      <Col lg="12" className="mt-2">
                        <Form.Group>
                          <div className="d-flex justify-content-between align-items-center">
                            <Form.Label className="text-secondary">
                              Password
                            </Form.Label>
                          </div>
                          <Form.Control
                            type="password"
                            placeholder="Enter Password"
                            required
                            value={newPassword}
                            onChange={onChangeNewPassword}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg="12" className="my-2">
                        <Form.Group>
                          <div className="d-flex justify-content-between align-items-center">
                            <Form.Label className="text-secondary">
                              Re-enter Password
                            </Form.Label>
                          </div>
                          <Form.Control
                            type="password"
                            placeholder="Re-enter Password"
                            required
                            value={confirmPassword}
                            onChange={onChangeConfirmPassword}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        onSubmit();
                      }}
                      variant="btn btn-primary btn-block"
                    >
                      New Password
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default connect(mapStateToProps)(NewPassword);
