import jwt_decode from "jwt-decode";

export function IsUserViewingAs() {

		let token = localStorage.getItem('user')
        if(token){

			var decoded = jwt_decode(token);
	        console.log(decoded);
	        var viewing_as = decoded["viewing_as"] !== undefined;
	        console.log("viewing_as?" + viewing_as);
	        if(viewing_as === true)
	        {
	           console.log("user is viewing as user " + decoded["viewing_as"]);
	           return viewing_as;
	        }
	        else
	        {
	        	return false;
	        }

	    }

	    return false;

}
