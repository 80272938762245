import React, { useEffect, useState } from 'react';
import api from "../../../helpers/api";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

function Coaches(props) {
  const [coaches, setCoaches] = useState([]);

  // Rest of your component...
  useEffect(() => {
    console.log("Coaches useEffect");
    
    api
    .get("/coaches/")
    .then((res) => res.data)
    .then(
      (result) => {
        console.info("/coaches/");
        console.info(result);

        setCoaches(result);
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        console.log(error);
      }
    );
}, []);

  return (
    <div className="card-container d-inline-flex justify-content-start">
    {coaches.map((coach, index) => (
        <Card style={{ width: '20rem', margin: '5px' }} bg={'light'} text={'dark'}>
        <Card.Img variant="top" src={process.env.REACT_APP_PUBLIC_URL + coach.image_url} />
        <Card.Body>
            <Card.Title>{coach.title}</Card.Title>
            <Card.Text>
            {coach.description}
            </Card.Text>
            {coach.comming_soon === 1 ? (
                <span style={{color: 'red'}}>Coming Soon</span>
                ) : (
                <Button variant="primary" onClick={() => props.history.push("/activities",{ coachId: coach.coach_id })}>Launch</Button>
            )}
            </Card.Body>
   
        </Card>
    ))}
    </div>
  );
}

export default Coaches;