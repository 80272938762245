import React, { useState } from "react";
import { Container,Col,Row,Form,Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import  Card from '../../../components/Card'
import {connect} from "react-redux";
import {getDarkMode} from '../../../store/mode'
import { ToastContainer, toast } from "react-toastify";
//img
import logo from '../../../assets/images/logo.png'
import darklogo from '../../../assets/images/logo-dark.png'
import { useHistory, useLocation } from 'react-router-dom'
import api from "../../../helpers/api";

const path = require('path');
require("dotenv").config({ path: path.resolve(__dirname, '..', '.env') });

function mapStateToProps(state) {
   return {
       darkMode: getDarkMode(state)
   };
}

const SignUp = (props) => {
      let history =useHistory()

      const [password, setPassword] = useState();
      const [email, setEmail] = useState();
      const [confirmPassword, setConfirmPassword] = useState();
      const [isTOSChecked, setIsTOSChecked] = useState(false);

      const location = useLocation();

      const onSubmit = () => {

         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
         if (!emailRegex.test(email)) {
            toast.error('Please enter a valid email address.');
            return;
         }
         if (!isTOSChecked) {
            toast.error('Please check the Terms of Service and Data Privacy checkbox before submitting the form.');
            return;
          }
         const validatePassword = (password) => {
            var regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z]).{8,}$/;
            return regex.test(password);
         };
         if (validatePassword(password)) {
            if (password === confirmPassword) {
            const post_body={
                  "email": email,
                  "password": password
               };

            api.post("/users/register/",
               post_body
            )
               .then((response) => response.data)
               .then((result) => {
                     // Do something with the data
                     toast.success("Successfully Registered");
                     history.push("/auth/sign-in");
                  })
               .catch((error) => {
                     console.error("An error occurred while parsing JSON:", error);
                     if (error.response && error.response.data && error.response.data.message) {
                        toast.error(error.response.data.message);
                     }
                     else {
                        toast.error("An error occurred");
                     }
                  }
                  );

            } else {
            toast.error("Password does't match");
            }
         } else {
            toast.error(
            "Password Should be at least 8 characters long has at least one number, symbol, upper case and one lower case"
            );
         }
      };

  const onChangeEmail = (e) => {
   setEmail(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const onChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };
    return (
        <>
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
        <section className="login-content">
         <Container className="h-100">
            <Row className="align-items-center justify-content-center h-100">
               <Col md="5" >
                  <Card className="p-3">
                     <Card.Body>
                        <div className="auth-logo">
                        <img src={logo} className={`img-fluid  rounded-normal  ${!props.darkMode ? 'd-none' : ''}`} alt="logo"/>
                           <img src={darklogo} className={`img-fluid  rounded-normal  ${props.darkMode ? 'd-none' : ''}`} alt="logo"/>
                        </div>
                        <h3 className="mb-3 font-weight-bold text-center">Getting Started</h3>
                        
                        <div className="mb-5">
                            <p className="line-around text-secondary mb-0"><span className="line-around-1">Register with email</span></p>
                        </div>
                        <Form>
                           <Row>
                              <Col lg="12">
                                 <Form.Group>
                                    <Form.Label className="text-secondary">Email</Form.Label>
                                    <Form.Control className="form-control" type="email" placeholder="Enter Email"
                                    value={email}
                                    onChange={onChangeEmail} />
                                 </Form.Group>
                              </Col>
                              <Col lg="12" className="mt-2">
                              <Form.Group>
                                 <div className="d-flex justify-content-between align-items-center">
                                    <Form.Label className="text-secondary">
                                       Password
                                    </Form.Label>
                                 </div>
                                 <Form.Control
                                    type="password"
                                    placeholder="Enter Password"
                                    required
                                    value={password}
                                    onChange={onChangePassword}
                                 />
                              </Form.Group>
                              </Col>
                              <Col lg="12" className="my-2">
                                 <Form.Group>
                                 <div className="d-flex justify-content-between align-items-center">
                                    <Form.Label className="text-secondary">
                                       Re-enter Password
                                    </Form.Label>
                                 </div>
                                 <Form.Control
                                    type="password"
                                    placeholder="Re-enter Password"
                                    required
                                    value={confirmPassword}
                                    onChange={onChangeConfirmPassword}
                                 />
                                 </Form.Group>
                              </Col>
                              <Col lg="12"  className="mt-2">
                                    <Form.Check  className="form-check-inline">
                                       <div className="custom-control custom-checkbox custom-control-inline mb-3">
                                          <Form.Check.Input type="checkbox" className="custom-control-input m-0" id="inlineCheckbox1"
                                          checked={isTOSChecked}
                                          onChange={(e) => setIsTOSChecked(e.target.checked)}
                                          />
                                          <Form.Check.Label className="custom-control-label pl-2" htmlFor="inlineCheckbox1">I agree to the <Link to="terms-of-service.html">Terms of Service </Link> and <Link to="privacy-policy.html">Privacy Policy</Link></Form.Check.Label>
                                       </div>
                                    </Form.Check >
                              </Col>
                           </Row>
                           <Button
                              type="submit"
                              onClick={(e) => {
                                 e.preventDefault();
                                 onSubmit();
                              }}
                              variant="btn btn-primary btn-block"
                           >Create Account</Button>
                           <div className="col-lg-12 mt-3">
                                <p className="mb-0 text-center">Do you have an account? <Link to="/auth/sign-in">Sign In</Link></p>
                           </div>
                        </Form>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </Container>
      </section>
        </>
    )
}

export default connect(mapStateToProps)(SignUp)