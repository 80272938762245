import { useState, useEffect }  from 'react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
import api from "../helpers/api";

const ActivityChat = ({ activityId }) =>{
    console.log("ActivityChat:activityId:" + activityId);
    const [messages, setMessages] = useState([]);
    const [isTyping, setIsTyping] = useState(false);

    useEffect(() => {
      const fetchMessages = async () => {
        try { 
          setIsTyping(true);
          const response = await api.get('/activities/chat_messages/' + activityId + '/');
          const messagesFromApi = response.data;
          setMessages(messagesFromApi);
          setIsTyping(false);
        } catch (error) {
          console.error('Error fetching messages:', error);
        }
      };
  
      fetchMessages();
    }, [activityId]);

    /*
    const [messages, setMessages] = useState([
        {
          message: "Hello, I'm the assistant for Life Coach Module 1. Ask me anything!",
          sentTime: "just now",
          sender: "assistant",
        },
      ]);
    const [isTyping, setIsTyping] = useState(false);
*/
    const handleSendRequest = async (message) => {
        const newMessage = {
          message,
          direction: 'outgoing',
          sender: "user",
        };
    
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setIsTyping(true);
    
        try {
          const response = await processMessageToChatGPT([...messages, newMessage]);
          console.log(response);
          const responseObject = JSON.parse(response);
          const content = responseObject.message;

          if (content) {
              const chatGPTResponse = {
              message: content,
              sender: "assistant",
            };
            setMessages((prevMessages) => [...prevMessages, chatGPTResponse]);
          }
        } catch (error) {
          console.error("Error processing message:", error);
        } finally {
          setIsTyping(false);
        }
      };
    
      async function processMessageToChatGPT(chatMessages) {
        const transformedMessages = chatMessages.map(chatMessage => ({
          content: chatMessage.message,
          role: chatMessage.sender
        }));
        const post_body = {
          Conversation: transformedMessages
        };
        return api
        .post("/activities/chat/" + activityId + "/", post_body)
        .then((res) => res.data)
        .then(
          (result) => {
            console.info(result);
            return JSON.stringify(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log(error);
            if (error.response && error.response.data && error.response.data.message) {
              return JSON.stringify({ "message": error.response.data.message });
            }
            else
            {
              return JSON.stringify({"message":"an error occured"});
            }

          }
        );
      }
    return( <div className="App">
    <div style={{ position:"relative", height: "600px", width: "700px"  }}>
      <MainContainer>
        <ChatContainer>       
          <MessageList 
            scrollBehavior="smooth" 
            typingIndicator={isTyping ? <TypingIndicator content="Assistant is typing" /> : null}
          >
            {messages.map((message, i) => {
              console.log(message)
              return <Message key={i} model={message} />
            })}
          </MessageList>
          <MessageInput attachButton={false} placeholder="Send a Message" onSend={handleSendRequest} />        
        </ChatContainer>
      </MainContainer>
    </div>
  </div>)
}

export default ActivityChat;