import React from "react";
import { Container, Col, Row, Button, Form } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import Card from "../../../components/Card";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { getDarkMode } from "../../../store/mode";
//img
import logo from "../../../assets/images/logo.png";
import darklogo from "../../../assets/images/logo-dark.png";
import AuthService from "../../../services/auth-service";

function mapStateToProps(state) {
  return {
    darkMode: getDarkMode(state),
  };
}

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "dba",
      password: "dba",
      darkMode: false,
      isSignedIn: false,
      view_as: this.props.location.state?.view_as
    };

    this.handleUserNameChange = this.handleUserNameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleUserNameChange(event) {
    this.setState({ username: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  async handleSubmit(event) {
    //alert('An login was submitted: ' + this.state.username);
    event.preventDefault();
    let isSignedIn = false;

    try {
      const results = await AuthService.login(
        this.state.username,
        this.state.password,
        this.state.view_as
      );
      if (results == true) {
        // window.location = "/";
        //alert("signed in successfully");
        toast.success("signed in successfully");
        isSignedIn = true;
        this.setState({ isSignedIn });
      } else {
        //alert("invalid username or password");
        toast.error("Invalid username or password");
        console.log(results);
        isSignedIn = false;
        this.setState({ isSignedIn });
      }
    } catch (error) {
      alert("caught error");
      console.log(error);
      isSignedIn = false;
      this.setState({ isSignedIn });
    }
    //alert("log in success:" + isSignedIn);
  }

  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* Same as */}
        <ToastContainer />
        {this.state.isSignedIn && <Redirect to="/" />}
        <section className="login-content">
          <Container className="h-100">
            <Row className="align-items-center justify-content-center h-100">
              <Col md="5">
                <Card className="p-3">
                  <Card.Body>
                    <div className="auth-logo">
                      <img
                        src={logo}
                        className={`img-fluid  rounded-normal  ${
                          !this.state.darkMode ? "d-none" : ""
                        }`}
                        alt="logo"
                      />
                      <img
                        src={darklogo}
                        className={`img-fluid  rounded-normal  ${
                          this.state.darkMode ? "d-none" : ""
                        }`}
                        alt="logo"
                      />
                    </div>
                    { this.state.view_as !== undefined &&
                      <p className="text-center text-secondary mb-4">
                        View As User {this.state.view_as}
                      </p>
                    }
                    <h3 className="mb-3 font-weight-bold text-center">
                      Sign-in
                    </h3>
                    <p className="text-center text-secondary mb-4">
                      Sign-in to your account to continue.
                    </p>

                    <div className="mb-5">
                      <p className="line-around text-secondary mb-0">
                        <span className="line-around-1">
                          Sign-in with email.
                        </span>
                      </p>
                    </div>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col lg="12">
                          <Form.Group>
                            <Form.Label className="text-secondary">
                              Email
                            </Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter Email"
                              value={this.state.username}
                              onChange={this.handleUserNameChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="12" className="mt-2">
                          <Form.Group>
                            <div className="d-flex justify-content-between align-items-center">
                              <Form.Label className="text-secondary">
                                Password
                              </Form.Label>
                              <Form.Label>
                                <Link to="/auth/recoverpw">
                                  Forgot Password?
                                </Link>
                              </Form.Label>
                              <Form.Label>
                                <Link to="/auth/sign-up">
                                  Register
                                </Link>
                              </Form.Label>
                            </div>
                            <Form.Control
                              type="password"
                              placeholder="Enter Password"
                              value={this.state.password}
                              onChange={this.handlePasswordChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Button
                        type="submit"
                        variant="btn btn-primary btn-block mt-2"
                      >
                        Log In
                      </Button>
                      <Col lg="12" className="mt-3">
                        <p className="mb-0 text-center">
                          Don't have an account?{" "}
                          <Link to="/auth/sign-up">Sign Up</Link>
                        </p>
                      </Col>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
        <div>version 0.1.5</div>
      </>
    );
  }
}

export default SignIn;
